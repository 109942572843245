import React,{ useState,forwardRef,useEffect } from 'react'
// import compose from "recompose/compose";
import {hBox} from "gx-web-ui/utils/flex";
import compose from 'recompose/compose'
import {inject,observer} from 'mobx-react'
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import SvgIcon from '@material-ui/core/SvgIcon';

import { motion } from "framer-motion"
import cx from 'classnames'

// import usePlacesAutocomplete from "use-places-autocomplete";

import Filter from '../assets/icons/Filter.svg'
import { Link } from "gatsby"





const SearchBarTwo = (props) => {

    
    const { searchInputOnChange, ifFilterButton, filterButtonClick, searchButtonClick} = props

    const { filterStore, uiStore, mapStore } = props

    // const { searchAddress, setSearchAddress} = filterStore
    
    const {
        isFilterCleared,
        searchAddress,
        setSearchAddress,
        searchList,
        setSearchList,
    } = filterStore

    const {
        clearSuggestions,
        debouncePlaceAutoComplete,
        searchPredictions,
    } = props.mapStore

    // const {setApiLoaded,apiLoaded} = uiStore
    const data = []

    const classes = useStyles();

    const [searching,setSearching] = useState(false)
    const [active, setActive] = useState(false)
    const [insideSearchAddress, setInsideSearchAddress] = useState('')


    const variants = {
        close:{
            opacity:0,
            duration:0.3
        },
        open:{
            opacity:1,
            duration:0.3
        }
    }


    // const {
    //     ready,
    //     value,
    //     suggestions: { status, data },
    //     setValue,
    //     clearSuggestions,
    // } = usePlacesAutocomplete({
    //     requestOptions: {
    //         types: ['(regions)'],
    //         componentRestrictions:{
    //             country:'au'
    //         },
    //         sessiontoken:'123321'
    //     },
    //     debounce: 300,
    // });


    const handleSearchInput = (value) => {
        // Update the keyword of the input element
        // const value = e.target.value
        // setValue(value);
        setInsideSearchAddress(value)
        setSearchAddress(value)
        if(value.length > 2){
            setSearching(true)
        }else if(value.length===0){
            setSearching(false)
        }
        debouncePlaceAutoComplete(value)
    };

    const handleSelect = ({ description, structured_formatting }) => () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        const {main_text} = structured_formatting
        setSearchAddress(main_text)
        setInsideSearchAddress(main_text)


        // if(!searchList.includes(main_text.trim())){
        //     setSearchList([...searchList,main_text])
        // }
        // setSearchAddress('')
        setSearching(false)
        clearSuggestions();
    
        // Get latitude and longitude via utility functions
        // getGeocode({ address: description })
        // .then((results) => getLatLng(results[0]))
        // .then(({ lat, lng }) => {
        //     console.log("📍 Coordinates: ", { lat, lng });
        // })
        // .catch((error) => {
        //     console.log("😱 Error: ", error);
        // });
    };

    const renderSuggestions = () => {
        if(searchPredictions.length>0){
            return searchPredictions.map((suggestion) => {
            const {
                id,
                structured_formatting: { main_text, secondary_text },
                description,
            } = suggestion;

            return (
                <motion.div key={description} whileHover={{backgroundColor:'#eeeeee'}} transition={{type:'tween',duration:0.2}} onClick={handleSelect(suggestion)} className={classes.suggestions} >
                    <strong>{main_text}</strong> <small>{secondary_text}</small>
                </motion.div>
                );
            })
        }else{
            return (
                <motion.div key={1} className={classes.noData}>
                    <strong>No matching address</strong>
                </motion.div>
            )
        }
    }

    return(
        <div style={{position : 'relative'}}>
            <Paper component="form" elevation={0}  className={ ifFilterButton ? classes.root : classes.rootWithShadow} >
                <motion.div  
                    variants={{
                        inactive:{borderColor:'rgba(0,0,0,0.5)'},
                        active:{borderColor:'rgba(0,0,0,0.7)'}
                    }}
                    animate={active?'active':'inactive'}
                    initial='inactive'
                    className={cx(classes.input,{[classes.inactiveInput]:!active})} 
                    style={{borderBottomLeftRadius:searching?0:4,minWidth:searchList.length>0?0:250}}
                >
                    <InputBase
                        className={classes.input}
                        placeholder="Enter suburb / postcode"
                        inputProps={{ 'aria-label': '"Enter suburb / postcode"' }}
                        value = {insideSearchAddress}
                        onChange={(e)=>handleSearchInput(e.target.value)}
                        fullWidth
                    />
                </motion.div>
                
                {
                    ifFilterButton && 
                    <div>
                        <IconButton  className={classes.iconButton} aria-label="search"  onClick={filterButtonClick}>
                            <img src={Filter} style={{ 'color' : 'grey', width: 24, height: 24}}/>
                        </IconButton>
                    </div>
                }
                <div>
                    <Link to="/explore/">
                        <IconButton className={classes.iconButton} aria-label="search" style={{'backgroundColor' : 'black' }} onClick={searchButtonClick}>
                            <SearchIcon style={{'color' : 'white',width: 24, height: 24}}/>
                        </IconButton>
                    </Link>
                    {/* {
                        !ifFilterButton ? 
                            <IconButton className={classes.iconButton} aria-label="search" style={{'backgroundColor' : ifFilterButton ?'black' : 'rgb(0,0,0,0.3)' }} onClick={searchButtonClick} disabled={true}>
                                <SearchIcon style={{'color' : 'white',width: 24, height: 24}}/>
                            </IconButton>
                            :
                            <Link to="/explore/">
                                <IconButton className={classes.iconButton} aria-label="search" style={{'backgroundColor' : ifFilterButton ?'black' : 'rgb(0,0,0,0.3)' }} onClick={searchButtonClick}>
                                    <SearchIcon style={{'color' : 'white',width: 24, height: 24}}/>
                                </IconButton>
                            </Link>
                    } */}
                </div>
                
            </Paper>
            <motion.div animate={searching?'open':'close'} initial='close' variants={variants} className={  ifFilterButton ? classes.matchOptions : classes.matchOptionsWithRoot}>
                {renderSuggestions()}
            </motion.div>
        </div>
        
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
    //   padding: '2px 4px',
        height : 64,
        paddingLeft:16,
        display: 'flex',
        alignItems: 'center',
        background:'transparent',
    },
    rootWithShadow: {
        //   padding: '2px 4px',
        height : 64,
        paddingLeft:16,
        display: 'flex',
        alignItems: 'center',
        background:'transparent',
        boxShadow : '1px 1px 6px 0 rgba(0, 0, 0, 0.2)',
        borderRadius : '0 4px 4px 4px'
    },
    input: {
        background:'transparent',
        height : 64,
        flex: 1,
    },
    iconButton: {
        padding: 10,
        borderRadius : 4,
        border : '1px solid #ccc',
        width : 54,
        height : 54,
        marginRight : 6
    },
    divider: {
        height: 28,
        margin: 4,
    },
    matchOptions : {
        // position:'absolute',
        width : '100%',
        maxHeight : 90,
        overflowY : 'scroll',
        // left:-1.5,
        // right:-1,
        paddingLeft : 16,
        backgroundColor:'white',
        // borderRadius:'0 0 4px 4px',
        borderRadius:4,
        borderTopRightRadius:0,
        borderTopLeftRadius:0,
        borderBottomRightRadius:4,
        borderWidth:1.5,
        borderTopWidth:0,
        // borderStyle:'solid',
        // borderColor:'rgba(0,0,0,0.7)', 
        zIndex:2,
        '&:focused':{
            borderColor:'#000', 
        },
    },
    matchOptionsWithRoot : {
        width : '100%',
        maxHeight : 90,
        overflowY : 'scroll',
        // left:-1.5,
        // right:-1,
        paddingLeft : 16,
        backgroundColor:'white',
        // borderRadius:'0 0 4px 4px',
        borderRadius:4,
        borderTopRightRadius:0,
        borderTopLeftRadius:0,
        borderBottomRightRadius:4,
        borderWidth:1.5,
        borderTopWidth:0,
        // borderStyle:'solid',
        // borderColor:'rgba(0,0,0,0.7)', 
        zIndex:2,
        '&:focused':{
            borderColor:'#000', 
        },
        boxShadow : '1px 1px 6px 0 rgba(0, 0, 0, 0.2)',
        // borderBottom : '1px solid rgb(0,0,0,0.3)',
        // borderLeft : '1px solid rgb(0,0,0,0.3)',
        // borderRight : '1px solid rgb(0,0,0,0.3)',
    }
}),{index:1});


export default compose(
    inject('filterStore','uiStore','mapStore'),
    observer
)(SearchBarTwo)
