import React,{ useState, useEffect ,useRef, useLayoutEffect} from 'react'
// import compose from "recompose/compose";
import {vBox} from "gx-web-ui/utils/flex";
import { CaretRightOutlined } from '@ant-design/icons';
import "../assets/styles/antTab.less"

import withWidth from '@material-ui/core/withWidth'

import SlidablePanel from '../components/common/SlidablePanel'
import ProjectCard from '../components/property/ProjectCard';
import PropertyCard from '../components/property/PropertyCard';

import {compose} from 'recompose';

import {inject,observer} from 'mobx-react'
import { useTheme,makeStyles,createStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import {propertySlugGeneratorFromRaw} from '@src/utils/slug'   
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography';        

import { Tabs, Radio } from 'antd';
import Loading from './common/Loading';

import Grid from '@material-ui/core/Grid';

import SavedSearchCard from '@src/components/property/SavedSearchCard'
import LandingPagePropertyTab from './LandingPagePropertyTab'
import ArrowLeft from '@material-ui/icons/ArrowBack'
import ArrowRight from '@material-ui/icons/ArrowForward'

const { TabPane } = Tabs;

const tabTextBoxShadow = {boxShadow : '1px 1px 6px 0 rgba(0, 0, 0, 0.3)'}

const tabPanelStyle = {borderRadius:'4px 4px 4px 4px', background : 'transparent', marginTop : 52}

const useStyles = makeStyles((theme) => createStyles({
    root: {
        overflow: "hidden",
        width: "inherits",
        alignContent: "center",
        // position : 'relative'
    }
}))

const LandingPagePropertyTabPanel = (props) => {

    const theme = useTheme()

    const classes = useStyles()
    const {parentDivWidth,appStore,propertyStore, uiStore} = props

    const {isShowRecommendation} = uiStore
    const {getMapProperties_,  getLandPageProperties_, nearbyPropertyList, allPropertyList, collectionList, savedSearchList, recommendationList,  isBackgroundDataLoading, landingPageLoading} = props.propertyStore

    const {loggedInUser} = appStore
    const [activeKey, setActiveKey] = useState(1)
    const [sliderControl , setSliderControl] = useState({
        currentIndex: 0,
        sliderCount: savedSearchList.length,
        disablePrev: false,
        disableNext: false,
    })

    const sliderRef = useRef();

    useEffect(() => {
        getLandPageProperties_()
        // getNearbyProperties_()
    }, [appStore.loggedInUser])
    
    const boxShadow = {boxShadow:`0px 4px 10px 0px ${theme.palette.grey[400]}`}

    const onTabClick = (actKey) =>{
        setActiveKey(actKey)
    }

    
    const menuItems = loggedInUser ? 
    [
        {
            key : 1,
            dataList : allPropertyList,
            label : "New developments highlight"
        },
        {
            key : 2,
            dataList : nearbyPropertyList,
            label : "Properties nearby"
        },
        {
            key : 3,
            dataList : collectionList,
            label : "Collections"
        },
        {
            key : 4,
            // dataList : savedSearchList,
            dataList : savedSearchList,
            label : "Saved search"
        }
    ]
    :
    [
        {
            key : 1,
            dataList : allPropertyList,
            label : "New developments highlight"
        },
        {
            key : 2,
            dataList : nearbyPropertyList,
            label : "Properties nearby"
        }
    ]

    // console.log(menuItems[1].dataList)

    const handleBack = () => {
        sliderRef.current.slickPrev();
    };
    const handleForward = () => {
        sliderRef.current.slickNext();
    };

    const updateSliderControl = sliderControl => {
        setSliderControl({ ...sliderControl });
    }


    const Cards = savedSearchList.map((data,index) => {
        return (
            <div key={index} >
                <SavedSearchCard key={index} info={data}/>
            </div>
        );
    });


    // console.log(sliderControl)

    return(
        <React.Fragment>
            <div style={{marginTop : 120}} />
            <LandingPagePropertyTab parentDivWidth = {parentDivWidth}/>
            <div style={{marginBottom : 120}} />
            {
                isShowRecommendation && <div style={{...vBox('center'),width : "100%", background : 'rgb(0,0,0,0.1)'}}>
                    <div style={{width:parentDivWidth>= 1315 ? 1315 : 870 }}>
                        <div style={{marginTop : 30}}>

                        </div>
                        <Typography variant='h5'  style={{ color : 'rgb(0,0,0,0.4)', fontWeight : 700, paddingLeft : 12.5}}> {"Recommended for you"}</Typography>
                        <div style={{marginTop : 20}}>

                        </div>
                        <Grid container style={{width : '100%',margin : 0}}>
                            { !_.isEmpty(recommendationList) &&  recommendationList.map((data,index)=>
                                <Grid item key={index} style={{width:410,height:250, marginLeft : 12.5, marginRight : 12.5}}>
                                    {
                                        data.processedData.isProject ? 
                                        <ProjectCard onClick={()=>window.open(`/${propertySlugGeneratorFromRaw(data.rawData)}`,'_blank')} rootStyle={boxShadow} project={data.processedData}/>
                                        :
                                        <PropertyCard onClick={()=>window.open(`/${propertySlugGeneratorFromRaw(data.rawData)}`,'_blank')} rootStyle={boxShadow}  property={data.processedData}/>
                                    }
                                </Grid>
                            )}
                        </Grid>
                        
                        <div style={{marginBottom : 30}}>

                        </div>
                    </div>
                </div>
            }

        </React.Fragment>
            
        
    )
}

export default compose(inject('propertyStore','appStore','uiStore'),observer )(LandingPagePropertyTabPanel)