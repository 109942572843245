import React,{useState} from "react";
import Slider from "react-slick";
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { isNullOrUndefined } from 'util';

//hoc components
import { vBox, hBox } from "gx-web-ui/utils/flex";
import { withStyles } from "@material-ui/core";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { useTheme,makeStyles,createStyles } from '@material-ui/core/styles';
//mui components

import '../../assets/styles/slick.css';


const useStyles = makeStyles((theme) => createStyles({
    root: {
        overflow: "hidden",
        width: "inherits",
        alignContent: "center"
    }
}))


const SlidablePanel = (props) =>{
// class SlidablePanel extends React.Component {

    // state = {
    //     currentIndex: 0
    // }



    const { sliderRef, cards, updateSliderControl,sliderCount } = props;

    const classes = useStyles()

    const [currentIndex, setCurrentIndex] = useState(0)

    const handleSliderControlUpdate = sliderControl => {
        updateSliderControl && updateSliderControl(sliderControl);
    }


    const canGo = async (currentIndex) => {
        // const { sliderCount } = this.props;
        if (currentIndex >= 0 && currentIndex < sliderCount) {
            setCurrentIndex(currentIndex);
        }
        console.log('newIndex:', currentIndex);
        handleSliderControlUpdate({ currentIndex });
    }

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        touchMove: false,
        speed: 100,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: false,
        centerPadding: '60px',
        afterChange: current => canGo(current),
        responsive: [{
            breakpoint: 1315,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }]
    };

    return (
        <div className={classes.root}>
            {<Slider ref={sliderRef} className="card-slider" {...settings}>
                {cards}
            </Slider>}
        </div>
    );
}

// SlidablePanel.propTypes = {
//     cards: PropTypes.array.isRequired,
//     sliderRef: PropTypes.object.isRequired,
//     backgroundColor: PropTypes.string,
//     sliderCount: PropTypes.number.isRequired,
//     currentIndex: PropTypes.number.isRequired,
//     updateSliderControl: PropTypes.func,
// };

export default SlidablePanel;