import React, { Component, useState, useEffect } from 'react'
import {inject,observer} from 'mobx-react'
import compose from 'recompose/compose'
import { hBox,vBox } from "gx-web-ui/utils/flex";
import _ from 'lodash'
import { useTheme,makeStyles,createStyles } from '@material-ui/core/styles';
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
// import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';

// import { useTheme,makeStyles,createStyles } from '@material-ui/core/styles';
// import ProjectSummaryCover from './ProjectSummaryCover'
// import Typography from '@material-ui/core/Typography';
// import Avatar from '@material-ui/core/Avatar';

import { shorterAUD } from '@src/utils/currency';
import sortBy from 'lodash/sortBy'
import PropertySettingIcons from './PropertySettingIcons'
import { motion } from "framer-motion"
import UnitModal from  '@src/components/property/UnitModal'
import Typography from '@material-ui/core/Typography';

const SavedSearchCard = props => {
    const {
        filterStore,
        info,
        rootStyle = null,
        motionProps = null,
        onClick
    } = props

    const {
        searchList,
        isRentFilter,
        filterType,
        actFilterSettings,
        generateBedroomTitle,
        generateBathroomTitle,
        generateParkingsTitle,
        // bedrooms,
        // bathrooms,
        // parkings
    } = filterStore

    const theme = useTheme()
    const styles = useStyles()
    const [selectedUnit, setSelectedUnit] = useState(null)

    const translate= (type) =>{
        return type == 'sale' ? 'Buy' : 'Rent'
    }

    const displayPrice = (priceObj) =>{
        return (priceObj.from === null && priceObj.to === null) ? 'Any price' :  priceObj.from === null  ? `Under ${shorterAUD(_.get(priceObj, 'to'))}` :  priceObj.to === null  ?  `Over ${shorterAUD(_.get(priceObj, 'from'))}` :  ` Between ${shorterAUD(_.get(priceObj, 'from')) || " "} and ${shorterAUD(_.get(priceObj, 'to'))} `
    }

    const displayPostcode = (postcodeArray) =>{

        return postcodeArray.length === 0 ? 'Any suburb' : postcodeArray.length === 1 ? `${postcodeArray[0]}` : `${postcodeArray[0]} and others`
    }

    const title = (info) => <Typography variant='subtitle1'  style={{color : 'black', fontWeight : 600, display:"flex"}}> { displayPostcode(_.get(info, 'postcode') || []) }</Typography>
    const subtitle = (info) => <div style={{...hBox('center')}}>
        <Typography variant='body2'  style={{color : theme.palette.grey[700], fontWeight : 500}}> { `${translate(info.listingType)} •`}</Typography>
        <Typography variant='body2'  style={{color : theme.palette.grey[700], marginLeft : 4}}> {displayPrice(info.priceRange) }</Typography>
    </div>

    return (
        <ButtonBase onClick={onClick} >
            <motion.div className={styles.root} style={{...rootStyle}} {...motionProps}>
                {title(info)}  
                {subtitle(info)}
                <div style={{height : 4}}></div>
                    <PropertySettingIcons 
                        bedrooms={generateBedroomTitle(info.bedrooms)}
                        bathrooms={generateBathroomTitle(info.bathrooms)}
                        carspaces={generateParkingsTitle(info.parkings)}
                        reverse
                        color={theme.palette.grey[700]}
                    />
            </motion.div>
        </ButtonBase>
        
    )
}

const useStyles = makeStyles((theme) => createStyles({
    scrollView: {
        flex: 1,
    },
    header:{
        backgroundColor:"white",
        padding:8,
        ...hBox('center'),
    },
    headerText:{
        // ...typography.body1,
        fontSize:14,
        fontWeight:300,
        color:'black'
    },
    root: {
        background : 'rgb(0,0,0,0.15)',
        width : "100%",
        padding : 16,
        width : 410,
        height : 110,
        borderRadius:5,
        overflow:'hidden',
        userSelect:'none',
    },
    listView: {
    },
    video: {
        flex: 1,
        width: '100%',
        height: '100%',
        // borderRadius: 13,
    },
    wrapper: {
    },
    slideDefault: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    paginationStyle: {
        bottom: 1
    },
    buttonWrapperStyle: {
        color: 'grey'
    },
    buttonText: {
        position: 'absolute',
        zIndex:1,
        fontWeight: 300,
        top:'calc(50% - 15px)',
        width:30,
        height:30,
        cursor:'pointer',
        fontSize: 34,
        color: 'white',
        textShadow: '4px 4px 10px rgba(0, 0, 0, 0.79)'
    },
    img:{
        height:220,
        width:400,
        objectFit:'cover'
    },
    image:{
        width:25,
        height:25,
        resizeMode: 'cover',
        marginRight:8,
    },
    planLine:{
        ...hBox('center','space-between'),
        padding:8
    },
    planText:{
        // ...typography.body1,
        fontWeight:500,
        color:'black'
    },
    unitText:{
        // ...typography.body1,
        fontWeight:400
    },
    plan:{
        backgroundColor:'white'
    },
    houseSetting:{
        paddingTop:4
    },
    divider:{
        width: '96%',
        height: 1,
        marginLeft:'2%',
        backgroundColor: theme.palette.grey[300],
    },
    rightArrow:{
        width:20,
        height:20,
        transform:'rotate(180deg)'
    },
    alignH:{
        ...hBox('center')
    },
    btn:{
        width:'100%',
        display:'block'
    }
}))
export default compose(
    inject('filterStore','uiStore'),
    observer
)(SavedSearchCard)