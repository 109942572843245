import React,{ useState,forwardRef,useEffect } from 'react'
// import compose from "recompose/compose";
import {hBox} from "gx-web-ui/utils/flex";

import { Input,Button } from 'antd';

import { makeStyles } from '@material-ui/core/styles';
import {compose} from 'recompose'
import {inject,observer} from 'mobx-react'
import loadGMaps from '../utils/loadGMaps'


import {
    ExclamationCircleFilled,
    ExclamationCircleOutlined,
    UserOutlined,
    LockOutlined,
    EyeTwoTone,
    EyeInvisibleOutlined
} from '@ant-design/icons';

import SearchBar from './SearchBar'
import SearchBarTwo from './SearchBarTwo'

const { Search } = Input;


const LandingPageSearchBar = (props) => {

    const {type, filterStore, propertyStore, ifFilterButton=true, style=null} = props

    const {setShowCollectionFilter,setCurrentFilterType} = filterStore

    const {getMapProperties_  } = propertyStore

    const [searchInputValue,setSearchInputValue] = useState('')
    const [mapScriptLoaded, setMapScriptLoaded] = useState(false);


    const onChange = (value) =>{
        setSearchInputValue(value)
    }

    const onFilterClick = ()=>{
        setShowCollectionFilter(true)
    }


    
    const onSearchClick = ()=>{
        // console.log("this should be displayed")
        setCurrentFilterType(type)
        getMapProperties_()
    }

    return <SearchBarTwo searchInputOnChange={onChange} ifFilterButton={ifFilterButton} filterButtonClick={onFilterClick} searchButtonClick={onSearchClick}/>

}
export default compose(inject('filterStore','uiStore','propertyStore'),observer)(LandingPageSearchBar)
