import React, { Suspense, useState, useEffect ,useRef } from "react";
import Container from "../components/container"
import Image from "../components/image"
import SEO from "../components/seo"
import {compose} from 'recompose'
import {inject,observer} from 'mobx-react'
import {vBox,hBox} from "gx-web-ui/utils/flex";
import Button from '@material-ui/core/Button'
import BlackBtn from '@src/components/common/BlackBtn'
import FilterView from '../components/filter/FilterView'
import WhiteOutlinedBtn from '@src/components/common/WhiteOutlinedBtn'
import { makeStyles } from '@material-ui/core/styles';
import {Modal,Divider} from 'antd'
import { Link } from "gatsby"
import MuiLink from '@material-ui/core/Link';
import LandingPageSearchComponent from './LandingPageSearchComponent'
// import ReactDependentScript from 'react-dependent-script';
import ButtonBase from '@material-ui/core/ButtonBase';
import LandingPageSearchBar from './LandingPageSearchBar'

import loadGMaps from '../utils/loadGMaps'

const useStyles = makeStyles(theme=>({
    root:{
        // width:400
    },
    filterWrap:{
        ...hBox('center','center'),
        overflowY:'auto'
    },
    alignH:{
        ...hBox('center','flex-start')
    },
    alignHEnd : {
        ...hBox('center','flex-end')
    },
    bottomLine:{
        ...hBox('center','center')
    },
    content:{
        paddingTop:8,
        paddingBottom:8
    },
    clearBtn:{
        color:theme.palette.clientPrimary.main
    },
    activeButton : {
        zIndex : 1,
        background : 'white', 
        height : 38,  
        padding : '5px 16px 5px 16px',
        borderRadius : '4px 4px 0 0', 
        borderTop : '1px solid rgb(0,0,0,0.3)',
        borderLeft : '1px solid rgb(0,0,0,0.3)',
        borderRight : '1px solid rgb(0,0,0,0.3)',
    },
    inactiveButton : {
        zIndex : 1,
        background : 'black', 
        height : 38,  
        borderRadius : '4px 4px 0 0',
        padding : '5px 16px 5px 16px',
    }
}))

const LandingPageFilterSearch = (props) => {
    const {filterStore,propertyStore} = props

    const {getMapProperties_} = propertyStore

    const {setCurrentFilterType} = filterStore

    const [activeKey, setActiveKey] = useState(1)

    const menuItems = [
        {
            key : 1,
            label : "Buy new",
            value : "newDevelopment"
        },
        {
            key : 2,
            label : "Buy existing",
            value : "buyExisting"
        },
        {
            key : 3,
            label : "Rent",
            value : 'rent'
        }
    ]

    const {
        setShowCollectionFilter,
        showCollectionFilter,
        clearFilter
    } = filterStore

    const settingRef = useRef(null)

    const classes = useStyles() 

    const onApply=()=>{
        settingRef.current.save()
        setShowCollectionFilter(false)
        setCurrentFilterType(menuItems[activeKey-1]["value"])
        getMapProperties_()
        // closeFilter()
        // console.log('should display')
    }
    const onClose=()=>{
        // setVisible(false)
        setShowCollectionFilter(false)
    }
    const onClear=()=>{
        settingRef.current.clear()
    }
    
    const onTabClick = (actKey) =>{
        setActiveKey(actKey)
    }
        
   


    return <Modal
            width={900}
            // height = {900}
            visible={showCollectionFilter}
            footer={null}
            style={{...vBox('center'),height : 900}}
            // onOk={(e)=>{
            //     e.stopPropagation()
            //     console.log('ok')
            //     }
            // }
            // // okButtonProps={{disabled:!isConfirmable()}}
            onCancel={()=>{
                onClose()
                // cleanForm()
                // setIsLoginMode(true)
            }}
            // okText={'Confirm'}
        >
            {/* <div style={{flex: '0 0 400px',position:'relative', margin : 0, width :  parentDivWidth >= 1315 ? 1315 : 870 }} ></div> */}
            <div className={classes.alignH} style={{height : "100%"}}>
                <div style={{width : '100%'}}>
                    {
                        menuItems.map(item=>
                            <React.Fragment key={item.key}>
                                <ButtonBase  className ={  activeKey===item.key  ?  classes.activeButton : classes.inactiveButton } style={{marginLeft : item.key !== 1 ? 14 : 0}} disableRipple onClick={()=>onTabClick(item.key)}>
                                    <span style={{color :  activeKey===item.key  ? 'black' : 'white' , fontSize : 16 }}>{item.label}</span>
                                </ButtonBase>
                            </React.Fragment>
                        )
                    }
                    <LandingPageSearchBar type={menuItems[activeKey-1]["value"]} ifFilterButton={false}/>
                </div>
            </div>
            <div className={classes.alignHEnd}>
                <Button onClick={onClear} className={classes.clearBtn}>
                    Clear all
                </Button>
            </div>
            <div className={classes.filterWrap}>
                <FilterView ref={settingRef} style={{width:900,height:900}} hideBuyRent={true}/>
            </div>
            <Divider />
            <div className={classes.bottomLine}>
                {/* <Link onClick={onApply} to="/explore/" > */}
                <Link  to="/explore/" onClick={onApply} style={{textDecoration : 'underline', color : 'black'}}>
                    {/* <div>
                    <MuiLink onClick={onApply}> */}
                        See result
                    {/* </MuiLink>

                    </div> */}
                </Link>
            </div>
    </Modal>
}

export default compose(inject('filterStore','uiStore','propertyStore'),observer)(LandingPageFilterSearch)
