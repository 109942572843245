import React,{ useState, useEffect ,useRef, useLayoutEffect} from 'react'
// import compose from "recompose/compose";
import {navigate} from "gatsby"
import {hBox} from "gx-web-ui/utils/flex";
import { CaretRightOutlined } from '@ant-design/icons';
import "../assets/styles/antTab.less"

import withWidth from '@material-ui/core/withWidth'

import SlidablePanel from '../components/common/SlidablePanel'
import Slider from "react-slick";
import ProjectCard from '../components/property/ProjectCard';
import PropertyCard from '../components/property/PropertyCard';

import {compose} from 'recompose';

import {inject,observer} from 'mobx-react'
import { useTheme,makeStyles,createStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography';  
import {propertySlugGeneratorFromRaw} from '@src/utils/slug'      

import { Tabs, Radio } from 'antd';
import Loading from './common/Loading';

import Grid from '@material-ui/core/Grid';

import SavedSearchCard from '@src/components/property/SavedSearchCard'

import ArrowLeft from '@material-ui/icons/ArrowBack'
import ArrowRight from '@material-ui/icons/ArrowForward'

const { TabPane } = Tabs;

const tabTextBoxShadow = {boxShadow : '1px 1px 6px 0 rgba(0, 0, 0, 0.3)'}

const tabPanelStyle = {borderRadius:'4px 4px 4px 4px', background : 'transparent', marginTop : 52}

const useStyles = makeStyles((theme) => createStyles({
    root: {
        overflow: "hidden",
        width: "inherits",
        alignContent: "center",
        // position : 'relative'
    }
}))

const LandingPagePropertyTab = (props) => {

    const theme = useTheme()

    const classes = useStyles()
    const {parentDivWidth,appStore,propertyStore, uiStore,filterStore} = props

    const {setIsShowRecommendation,checkLogin } = uiStore
    const {getMapProperties_,  getLandPageProperties_, nearbyPropertyList, allPropertyList, collectionList, savedSearchList,  isBackgroundDataLoading, landingPageLoading,favorProperty_,
        unfavorProperty_,
        toggleFavorByIndexByType} = propertyStore

    const {setFilter} = filterStore
    const {loggedInUser} = appStore
    const [activeKey, setActiveKey] = useState(1)
    const [sliderControl , setSliderControl] = useState({
        currentIndex: 0,
        sliderCount: savedSearchList.length,
        disablePrev: false,
        disableNext: false,
    })

    const sliderRef = useRef();
    
    const boxShadow = {boxShadow:`0px 4px 10px 0px ${theme.palette.grey[400]}`}

    const onTabClick = (actKey) =>{
        if(actKey === 4){
            setIsShowRecommendation(true)
        }else{
            setIsShowRecommendation(false)
        }
        setActiveKey(actKey)
    }

    
    const menuItems = loggedInUser ? 
    [
        {
            key : 1,
            dataList : allPropertyList,
            label : "New developments highlight",
            type : "newDevelopment"
        },
        {
            key : 2,
            dataList : nearbyPropertyList,
            label : "Properties nearby",
            type : "nearby"
        },
        {
            key : 3,
            dataList : collectionList,
            label : "Collections",
            type : "collection"
        },
        {
            key : 4,
            // dataList : savedSearchList,
            dataList : savedSearchList,
            label : "Saved search"
        }
    ]
    :
    [
        {
            key : 1,
            dataList : allPropertyList,
            label : "New developments highlight",
            type : "newDevelopment"
        },
        {
            key : 2,
            dataList : nearbyPropertyList,
            label : "Properties nearby",
            type : "nearby"
        }
    ]

    // console.log(menuItems[1].dataList)

    const handleBack = () => {
        sliderRef.current.slickPrev();
    };
    const handleForward = () => {
        sliderRef.current.slickNext();
    };

    const updateSliderControl = sliderControl => {
        setSliderControl({ ...sliderControl });
    }


    const Cards = savedSearchList.map((data,index) => {
        return (
            <div key={index} >
                <SavedSearchCard key={index} info={data} onClick={()=>{setFilter(data);navigate('/explore'); getMapProperties_()}}/>
            </div>
        );
    });

    const onToggleFavor = (listingId,index, type)=>(newState)=>{
        if(newState===true){
            favorProperty_(listingId)
        }else{
            unfavorProperty_(listingId)
        }
        toggleFavorByIndexByType(newState,index,type)
        getLandPageProperties_()
    }


    // console.log(sliderControl)

    return(
        <Loading loading={landingPageLoading}>
            <div style={{flex: '0 0 400px',position:'relative', margin : 0 ,  width :  parentDivWidth >= 1315 ? 1315 : 870 }} >
                <div style = {{...hBox('center','center')}}>
                    {
                        menuItems.map(item=>
                            <React.Fragment key={item.key}>
                                <ButtonBase  style={{background : activeKey===item.key ? 'black' : 'white', height : 38, padding : '5px 16px 5px 16px',borderRadius : 4, marginLeft : item.key !== 1 ? 14 : 0}} disableRipple onClick={()=>onTabClick(item.key)}>
                                    <span style={{color :  activeKey===item.key  ? 'white' : 'rgb(0,0,0,0.5)', fontSize : 18 }}>{item.label}</span>
                                </ButtonBase>
                            </React.Fragment>
                        )
                    }
                </div>
                <div style={{marginTop : 52, width : '100%'}}>
                        {
                            activeKey !== 4 ? 
                            <React.Fragment>
                                <Grid container style = {{width : '100%',margin : 0}}>
                                <React.Fragment>
                                    { !_.isEmpty(menuItems[activeKey-1].dataList) &&  menuItems[activeKey-1].dataList.map((data,index)=>
                                        <Grid item key={index} style={{width:410,height:270, marginLeft : 12.5, marginRight : 12.5, paddingBottom : 20}}>
                                            {
                                                data.processedData.isProject ? 
                                                <ProjectCard height={250} onClick={()=>window.open(`/${propertySlugGeneratorFromRaw(data.rawData)}`,'_blank')} rootStyle={boxShadow} project={data.processedData} favored={data.processedData.isSaved} onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId,index, menuItems[activeKey-1].type))}/>
                                                :
                                                <PropertyCard height={250}  onClick={()=>window.open(`/${propertySlugGeneratorFromRaw(data.rawData)}`,'_blank')} rootStyle={boxShadow}  property={data.processedData} favored={data.processedData.isSaved} onToggleFavor={checkLogin(onToggleFavor(data.processedData.listingId,index, menuItems[activeKey-1].type))}/>
                                            }
                                        </Grid>
                                    )}
                                </React.Fragment>
                                </Grid>
                                {activeKey === 1 &&
                                <div style={{width : "100%",...hBox('center','center')}}>
                                    <ButtonBase  style={{background : 'white', height : 38, border : '1px solid rgb(0,0,0,0.5)', padding : '5px 16px 5px 16px',borderRadius : 4}} disableRipple onClick={()=>navigate('/explore')}>
                                        <span style={{color : 'rgb(0,0,0,0.5)', fontSize : 18 }}>{"Explore more"}</span>
                                    </ButtonBase>
                                </div>}
                            </React.Fragment>
                            

                            :
                            <div>
                                <div className={classes.root}>
                                    <IconButton onClick={handleBack} style={{zIndex : 10, position : 'absolute', left : -8, top : 130, width : 36, height : 36, borderRadius : 1000, background : '#ffffff', opacity : 0.5,  ...boxShadow}}>
                                        <ArrowLeft style={{color : "black"}}/>
                                    </IconButton>
                                    <SlidablePanel
                                        sliderRef={sliderRef}
                                        currentIndex={sliderControl.currentIndex}
                                        sliderCount= {savedSearchList.length}
                                        updateSliderControl={updateSliderControl}
                                        cards={Cards}
                                    /> 
                                    <IconButton onClick={handleForward} style={{zIndex : 10, position : 'absolute', right : -8, top : 130, width : 36, height : 36, borderRadius : 1000, background : '#ffffff', opacity : 0.5,  ...boxShadow}}>
                                        <ArrowRight style={{color : "black"}}/>
                                    </IconButton>
                                </div>                         
                            </div>

                        }
{/* 
                        { !_.isEmpty(menuItems[activeKey-1].dataList) &&  menuItems[activeKey-1].dataList.map((data,index)=>
                            <React.Fragment>
                                {activeKey === 4 ? 
                                    <SlidablePanel
                                        sliderRef={this.sliderRef}
                                        currentIndex={sliderControl.currentIndex}
                                        sliderCount= {caseStore.caseCompanies[":listedPersons"].length}
                                        updateSliderControl={this.updateSliderControl}
                                        cards={Cards}
                                    /> 
                                : 
                                    <Grid item key={index} style={{width:410,height:250, marginLeft : 12.5, marginRight : 12.5}}>
                                        {
                                            data.processedData.isProject ? 
                                            <ProjectCard onClick={()=>window.open(`/project-${_.kebabCase(data.rawData.displayName)}-${data.rawData.id}`,'_blank')} rootStyle={boxShadow} project={data.processedData}/>
                                            :
                                            <PropertyCard onClick={()=>window.open(`/property-${_.kebabCase(data.rawData.displayName)}-${data.rawData.id}`,'_blank')} rootStyle={boxShadow}  property={data.processedData}/>
                                        }
                                    </Grid>
                                }
                            </React.Fragment>
                        )} */}
                    
                </div>
            </div>

        </Loading>
        
    )
}

export default compose(inject('propertyStore','appStore','uiStore','filterStore'),observer )(LandingPagePropertyTab)